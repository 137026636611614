<template>
  <v-form @submit.prevent="handleSend" v-model="isValid" ref="feedbackFormRef">
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="feedback.fname"
              :rules="[required]"
              :label="$t('first_name')"
              color="#2B2F3C"
            />
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="feedback.lname"
              :rules="[required]"
              :label="$t('last_name')"
              color="#2B2F3C"
            />
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="feedback.email"
              :rules="[required, email]"
              :label="$t('email')"
              color="#2B2F3C"
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="feedback.message"
              :rules="[required]"
              :label="$t('your_message')"
              color="#2B2F3C"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="#8C8C8C" text @click="handleClose">
        {{ $t("close") }}
      </v-btn>

      <v-btn :disabled="!isValid" color="#2B2F3C" text type="submit">
        {{ $t("send") }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { api } from "@/api";
import { emailRegexp } from "@/components/Common/helpers/validationRules";

const FEEDBACK_URL = "/sendfeedback";

export default {
  name: "ContactFeedbackForm",

  data: () => ({
    isValid: false,
    isShowForm: true,

    feedback: {
      fname: "",
      lname: "",
      email: "",
      message: "",
    },
  }),

  methods: {
    handleSend() {
      this.$refs.feedbackFormRef.validate();
      if (this.isValid) {
        this.isValid = false;
        this.senFeedBack();
      }
    },

    async senFeedBack() {
      await api.post(
        FEEDBACK_URL,
        {
          ...this.feedback,
          lang: this.$i18n.locale,
        },
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        }
      );
      this.$emit("feedBackMessageRecieve");
      this.clearFormData();
      this.$emit("closeClicked");
    },
    handleClose() {
      this.$emit("closeClicked");
    },
    clearFormData() {
      this.$refs.feedbackFormRef.reset();
    },
  },

  computed: {
    required() {
      return (v) => !!v || this.$t("field_required");
    },

    email() {
      return (v) => !v || emailRegexp.test(v) || this.$t("email_valid");
    },
  },
};
</script>

<style scoped></style>
